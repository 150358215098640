import { Link } from "gatsby";
import React from "react";

import { Page } from "@/components/Page";

export default () => (
  <Page>
    <h1>four 😵 four</h1>
    <h4>Can't find this page.</h4>
    <Link to="/">Try going home?</Link>
  </Page>
);
